import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';

// Components
import {
  WithHeader,
  Button,
  utils,
  Picto,
} from 'ui-library-unlocker';
import Footer from '../../components/templates/Footer/Footer';
import BackButton from '../../components/atoms/BackButton/BackButton';
import NotFound from '../404/404';
import ContactInfo from './ContactInfo';
import PropertyPostAd from './PropertyPostAd';
import PropertyCharPostAd from './PropertyCharPostAd';
import PropertyPictures from '../Property/PropertyPictures/PropertyPictures';
import PostAdSuccess from './PostAdSuccess';

// Services
import { quickSignUp, checkEmailIsNotAccount } from '../../services/identity';
import { getPropertyUI } from '../../services/uiBuilder';
import { createPostAd } from '../../services/property';

// Utils
import { contactInfoInitialValues, contactInfoSchema, propertyPostAdSchema } from '../../utils/forms/postAdSchema';
import {
  propertyInitialValues,
  propertyCharacteristicsInitialValues,
  propertyCharacteristicsSchema,
} from '../../utils/forms/propertySchema';

// Hooks
import useScroll from '../../hooks/useScroll';

import styles from './PostAd.module.scss';

function PostAd() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const { scrollContentToTop } = useScroll();

  const [step, setStep] = useState(1);
  const [ownerUID, setOwnerUID] = useState(null);
  const [propertyUID, setPropertyUID] = useState(null);

  useEffect(() => {
    document.title = t('postAd.browserTitle');
  }, [t]);

  const renderNav = useCallback(() => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Button
            onClick={() => navigate('/login')}
            label={t('login.title')}
            size={isMobile ? 'small' : 'medium'}
          />
        </li>
      </ul>
    </nav>
  ), [isMobile, navigate, t]);

  const handlePrevStep = useCallback(() => {
    setStep((prev) => prev - 1);
    scrollContentToTop();
  }, []);

  const handleNextStep = useCallback(() => {
    setStep((prev) => prev + 1);
    scrollContentToTop();
  }, []);

  const {
    data: propertyBuilders,
  } = useQuery({
    queryKey: ['property-builders'],
    queryFn: getPropertyUI,
  });

  const checkEmailMutation = useMutation({
    mutationFn: checkEmailIsNotAccount,
    keepPreviousData: true,
    onError: () => {
      contactInfoFormik.setErrors({
        ...contactInfoFormik.errors,
        email: t('postAd.contactInfo.alreadyExistingEmail'),
      });
    },
  });

  const contactInfoFormik = useFormik({
    initialValues: contactInfoInitialValues,
    validationSchema: contactInfoSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {
      if (checkEmailMutation.isError) {
        return contactInfoFormik.setErrors({
          ...contactInfoFormik.errors,
          email: t('postAd.contactInfo.alreadyExistingEmail'),
        });
      }
      return handleNextStep();
    },
  });

  const propertyFormik = useFormik({
    initialValues: {
      ...propertyInitialValues,
      status: 'available',
    },
    validationSchema: propertyPostAdSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {
      handleNextStep();
    },
  });

  const propertyCharFormik = useFormik({
    initialValues: propertyCharacteristicsInitialValues,
    validationSchema: propertyCharacteristicsSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {
      quickSignUpMutation.mutate({
        ...contactInfoFormik.values,
        companyName: contactInfoFormik.values.companyName || null,
      });
    },
  });

  const createPostAdMutation = useMutation({
    mutationFn: createPostAd,
    onSuccess: (res) => {
      setPropertyUID(res?.data?.uid);
      handleNextStep();
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          case 403: {
            utils.toast.error(t('global.form.errors.forbidden'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
        }
      }
    },
  });

  const quickSignUpMutation = useMutation({
    mutationFn: quickSignUp,
    onSuccess: (res) => {
      createPostAdMutation.mutate({
        ...propertyFormik.values,
        rentExcludingCharges: utils.euroToCents(propertyFormik.values.rentExcludingCharges),
        rentSupplement: utils.euroToCents(propertyFormik.values.rentSupplement || 0),
        charges: utils.euroToCents(propertyFormik.values.charges),
        characteristicEdition: {
          ...propertyCharFormik.values,
          minConsumption: utils.euroToCents(propertyCharFormik.values.minConsumption),
          maxConsumption: utils.euroToCents(propertyCharFormik.values.maxConsumption),
        },
        ownerUid: res?.data?.uid,
      });
      setOwnerUID(res?.data?.uid);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          case 409: {
            switch (err.response.data?.message) {
              case 'PERSON_LISTING_EMAIL_CONFLICT': {
                utils.toast.alert(t('postAd.contactInfo.errorResponses.PERSON_LISTING_EMAIL_CONFLICT'));
                break;
              }
              default: {
                utils.toast.alert(t('global.form.errors.forbidden'));
                break;
              }
            }
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const stepToDisplay = useMemo(() => {
    if (step === 1) {
      return (
        <ContactInfo
          className={styles.form}
          formik={contactInfoFormik}
          checkEmailMutation={checkEmailMutation}
        />
      );
    }
    if (step === 2) {
      return (
        <PropertyPostAd
          className={styles.form}
          formik={propertyFormik}
          propertyBuilders={propertyBuilders?.data}
        />
      );
    }
    if (step === 3) {
      return (
        <PropertyCharPostAd
          formik={propertyCharFormik}
          propertyBuilders={propertyBuilders?.data}
        />
      );
    }
    if (step === 4) {
      return (
        <PropertyPictures
          postAd
          userUID={ownerUID}
          propertyUID={propertyUID}
        />
      );
    }
    if (step === 5) {
      return <PostAdSuccess />;
    }
    return <NotFound />;
  }, [contactInfoFormik, step, propertyFormik, propertyBuilders, propertyCharFormik]);

  const handleSubmit = useCallback(() => {
    switch (step) {
      case 1:
        contactInfoFormik.handleSubmit();
        break;
      case 2:
        propertyFormik.handleSubmit();
        break;
      case 3:
        propertyCharFormik.handleSubmit();
        break;
      case 4:
        handleNextStep();
        break;
      default:
        break;
    }
  }, [step, contactInfoFormik, propertyFormik, propertyCharFormik, handleNextStep]);

  const shouldDisplayBackButton = useMemo(() => step > 1 && step < 4, [step]);

  const shoudlDisplayNextButton = useMemo(() => step < 5, [step]);

  return (
    <WithHeader
      logoLink="/register"
      navContent={renderNav()}
      isContained={false}
      className={utils.cn([
        styles.postAdViewHeader,
        'p-l-20 p-r-20',
      ])}
      displayBurger={false}
    >
      <div className={styles.postAdView}>
        <div>
          <div className={styles.heading}>
            <h1
              id="postAdTitle"
              className={styles.title}
            >
              <span>{t('postAd.title')}</span>
              <Picto height={30} icon="building-4" color="var(--color-primary)" className={styles.titleIcon} />
            </h1>
            <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />
          </div>
          <div className={styles.content}>
            {shouldDisplayBackButton && (
              <BackButton
                label={t('global.goBack')}
                onClick={handlePrevStep}
              />
            )}
            {stepToDisplay}
          </div>
        </div>
        <div>
          {shoudlDisplayNextButton && (
            <Button
              variant="primary"
              size="large"
              onClick={handleSubmit}
              loading={quickSignUpMutation.isLoading || createPostAdMutation.isLoading}
            >
              {t('onboarding.next')}
            </Button>
          )}
        </div>
      </div>
      <Footer className={styles.footer} />
    </WithHeader>
  );
}

PostAd.propTypes = {
};

PostAd.defaultProps = {};

export default PostAd;
