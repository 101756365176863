import {
  PAYMENT_AND_ACCOUNT_STATUS_LIST,
  LEASE_STATUS,
  ACCOUNT_ENROLMENT,
} from './constants';

export const getPaymentAndAccountStatusVariant = (status) => {
  switch (status) {
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULED_MANUAL:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.NOTIFIED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.PENDING:
      return 'secondary-outline';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.IN_WIRING:
      return 'secondary';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.EXPORTED:
      return 'tertiary';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SUCCESS:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.RECEIVED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SENT:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_WIRE:
      return 'success';

    case PAYMENT_AND_ACCOUNT_STATUS_LIST.CANCELED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.FAILED:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.NOT_DOABLE:
    case PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULING_ERROR:
      return 'error';

    default:
      return 'primary';
  }
};

export const getLeaseStatusVariant = (s) => {
  switch (s) {
    case LEASE_STATUS.CREATED:
      return 'secondary-outline';
    case LEASE_STATUS.SIGNED:
      return 'secondary';
    case LEASE_STATUS.TERMINATED:
      return 'error';
    case LEASE_STATUS.PENDING_SIGNATURE:
    case LEASE_STATUS.TERMINATION_SCHEDULED:
    case LEASE_STATUS.WAITING_DOCUMENT:
      return 'tertiary';
    default:
      return 'secondary-outline';
  }
};

export const getAccountEnrolmentVariant = (s) => {
  switch (s) {
    case ACCOUNT_ENROLMENT.TO_REVIEW:
      return 'tertiary';
    case ACCOUNT_ENROLMENT.SUBMITTED:
    case ACCOUNT_ENROLMENT.ESCALATED:
      return 'secondary';
    case ACCOUNT_ENROLMENT.VALIDATED:
      return 'success';
    case ACCOUNT_ENROLMENT.REFUSED:
    case ACCOUNT_ENROLMENT.BLOCKED:
      return 'error';
    case ACCOUNT_ENROLMENT.PENDING:
    case ACCOUNT_ENROLMENT.WAITING_FOR_MODIFICATION:
      return 'secondary-outline';
    case ACCOUNT_ENROLMENT.NOT_APPLICABLE:
      return 'primary';
    default:
      return 'error';
  }
};
