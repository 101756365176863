import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { omitBy, debounce } from 'lodash';

// Components
import {
  TextInput,
  Picto,
  Table,
  Pagination,
} from 'ui-library-unlocker';
import FiltersSideModal from '../../molecules/FiltersSideModal/FiltersSideModal';
import FiltersSelected from '../../molecules/FiltersSelected/FiltersSelected';
import SearchInput from '../../molecules/SearchInput/SearchInput';

// Hooks
import useFilters from '../../../hooks/useFilters';
import { useAppContext } from '../../../store/context';

import styles from './DataTable.module.scss';

function DataTable({
  className,
  searchLabel,
  getData,
  params,
  queryKey,
  columns,
  allFiltersOptions,
  setData,
  setDataLoading,
  loading,
  headerBtn,
  formatData,
  searchDisabled,
  searchHidden,
  forcedFilters,
  openable,
  alreadyOpened,
  children,
  omniSearch,
  omniSearchScope,
}) {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sorting, setSorting] = useState([]);
  const { context: { needDataTableRefetch }, dispatch } = useAppContext();
  const {
    filters,
    setFilters,
    resetFilters,
  } = useFilters();
  const [search, setSearch] = useState(filters?.search?.[0] || '');

  const getInitialOmniSearchValue = useCallback(() => {
    const omniFilter = Object.values(omniSearchScope)?.find((equivalent) => filters[equivalent]?.length > 0);
    if (!omniFilter) return null;
    return {
      scope: Object.keys(omniSearchScope).find((key) => omniSearchScope[key] === omniFilter),
      value: filters[omniFilter][0],
      label: filters[`${omniFilter}Label`][0],
    };
  }, [filters, omniSearchScope]);
  const [omniSearchValue, setOmniSearchValue] = useState(getInitialOmniSearchValue());

  const {
    data: listData,
    isFetching: listFetching,
    refetch: refetchList,
  } = useQuery({
    queryKey: [queryKey, page, itemsPerPage, filters, sorting],
    queryFn: () => {
      setDataLoading(true);
      return getData({
        page,
        itemsPerPage,
        filters: {
          ...filters,
          orderBy: sorting[0],
          ...(forcedFilters || {}),
        },
        ...(params || {}),
      });
    },
    onSettled: () => {
      setDataLoading(false);
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: false });
    },
    keepPreviousData: true,
  });

  const data = useMemo(() => formatData(listData?.data?.collection) || [], [listData, formatData]);

  useEffect(() => setData(listData?.data?.collection || []), [listData]);

  useEffect(() => {
    if (needDataTableRefetch === queryKey) refetchList();
  }, [needDataTableRefetch]);

  const filtersWithoutSearch = useMemo(() => omitBy(
    filters,
    (_v, k) => [
      'search',
      ...(Object.values(omniSearchScope) || []),
      ...(Object.values(omniSearchScope)?.map((item) => `${item}Label`) || []),
    ].includes(k),
  ), [filters, omniSearchScope]);

  const handleFiltersSubmit = useCallback((values) => {
    setPage(1);
    resetFilters({
      ...values,
      search: filters.search,
    });
    setFilterOpen(false);
  }, [resetFilters, filters.search]);

  const handleRemoveFilter = useCallback((filterKey, filterValue) => {
    setPage(1);
    setFilters(filterKey, filterValue);
  }, [setFilters]);

  const resetAllFilters = useCallback(() => {
    setPage(1);
    const filtersToKeep = { search: filters.search };
    Object.values(omniSearchScope)?.forEach((scope) => {
      filtersToKeep[scope] = filters[scope];
      filtersToKeep[`${scope}Label`] = filters[`${scope}Label`];
    });
    resetFilters(filtersToKeep);
  }, [resetFilters, filters, omniSearchScope]);

  const handleSorting = useCallback((orderBy) => {
    setSorting(orderBy);
  }, []);

  const handleOmniSearch = useCallback((value) => {
    setPage(1);
    setOmniSearchValue(value);
  }, []);

  const handleSearch = useCallback(debounce((value) => {
    setPage(1);
    setFilters('search', value || []);
  }, 500), [setFilters]);

  useEffect(() => handleSearch(search), [search]);

  useEffect(() => {
    if (!omniSearchValue) {
      const filtersToReset = {};
      Object.values(omniSearchScope)?.forEach((scope) => {
        filtersToReset[scope] = [];
        filtersToReset[`${scope}Label`] = [];
      });
      return resetFilters({
        ...filters,
        ...filtersToReset,
      });
    }
    return resetFilters({
      ...filters,
      [omniSearchScope?.[omniSearchValue.scope]]: [omniSearchValue.value],
      [`${omniSearchScope?.[omniSearchValue.scope]}Label`]: [omniSearchValue.label],
    });
  }, [omniSearchValue]);

  const displaySearchInput = useCallback(() => {
    if (searchHidden) return null;
    if (omniSearch) {
      return (
        <SearchInput
          onSelect={handleOmniSearch}
          value={omniSearchValue}
          className={styles.search}
          label={searchLabel || t('global.search.label')}
          disabled={searchDisabled}
          scope={Object.keys(omniSearchScope)}
        />
      );
    }
    return (
      <TextInput
        id="search"
        name="search"
        label={searchLabel || t('global.search.label')}
        className={styles.search}
        placeholder={searchLabel || t('global.search.label')}
        value={search}
        full
        onChange={(e) => setSearch(e.target.value)}
        disabled={searchDisabled}
        noInteractions={searchDisabled}
      />
    );
  }, [omniSearch, searchLabel, t, search, searchDisabled, handleOmniSearch, omniSearchValue, omniSearchScope]);

  return (
    <div className={className}>
      <div className={styles.actions}>
        <div className={styles.searchBar}>
          {displaySearchInput()}
          <button
            type="button"
            className={styles.filter}
            onClick={() => setFilterOpen(true)}
          >
            <Picto icon="setting" width="20" />
            <span>{t('global.filters.title')}</span>
          </button>
          <FiltersSideModal
            open={filterOpen}
            onClose={() => setFilterOpen(false)}
            allOptions={allFiltersOptions}
            onSubmit={handleFiltersSubmit}
          />
        </div>
        {headerBtn}
      </div>
      {!searchHidden && (
        <p className={styles.searchOptionsCount}>
          {listData?.data?.totalNumberOfItems > 0
            ? t('global.search.listResults', {
              count: listData?.data?.totalNumberOfItems,
              plural: listData?.data?.totalNumberOfItems > 1 ? 's' : '',
            })
            : t('global.search.noResults')}
        </p>
      )}
      <div className="m-t-20">
        <FiltersSelected
          filtersSelected={filtersWithoutSearch}
          filtersList={allFiltersOptions}
          onRemove={handleRemoveFilter}
          onRemoveAll={resetAllFilters}
        />
      </div>
      <div className={styles.content}>
        <Table
          fullWidth
          columns={columns}
          data={data}
          isLoading={listFetching || loading}
          sortViaApi
          onApiSort={handleSorting}
          apiSorting={sorting}
          openable={openable}
          alreadyOpened={alreadyOpened}
        >
          {children}
        </Table>
        <div className={styles.pagination}>
          <Pagination
            breakLine="..."
            totalCount={listData?.data?.totalNumberOfItems || 0}
            currentPage={page}
            onPageChange={(p) => setPage(p)}
            initialItemsPerPage={itemsPerPage}
            onItemsPerPageChange={(items) => setItemsPerPage(items)}
          />
        </div>
      </div>
    </div>
  );
}

DataTable.propTypes = {
  className: PropTypes.string,
  searchLabel: PropTypes.string,
  getData: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
  queryKey: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  allFiltersOptions: PropTypes.arrayOf(PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({})),
  })),
  setData: PropTypes.func,
  setDataLoading: PropTypes.func,
  loading: PropTypes.bool,
  headerBtn: PropTypes.node,
  formatData: PropTypes.func,
  searchDisabled: PropTypes.bool,
  searchHidden: PropTypes.bool,
  forcedFilters: PropTypes.shape({}),
  openable: PropTypes.bool,
  alreadyOpened: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  children: PropTypes.node,
  omniSearch: PropTypes.bool,
  omniSearchScope: PropTypes.shape({}),
};

DataTable.defaultProps = {
  className: '',
  searchLabel: '',
  params: {},
  columns: [],
  allFiltersOptions: [],
  setData: () => {},
  setDataLoading: () => {},
  loading: false,
  headerBtn: null,
  formatData: (data) => data,
  searchDisabled: false,
  searchHidden: false,
  forcedFilters: {},
  openable: false,
  alreadyOpened: false,
  children: null,
  omniSearch: false,
  omniSearchScope: {},
};

export default DataTable;
