/* eslint-disable max-len */
import { utils } from 'ui-library-unlocker';
import api from './_client';
import { getDateFilters } from '../utils/dates';

export const getPersonDocumentsFromAdmin = (uid, params) => api.get(`/person/admin/document/${uid}`, {
  params,
});

export const getCompanyDocumentsFromAdmin = (uid, params) => api.get(`/person/admin/document/${uid}`, {
  params,
});

export const getUsersFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[role][--in--]': filters.role ? filters.role.join?.(',') : 'ROLE_USER,ROLE_LESSOR',
    'filters[onboardingStatus][--in--]': filters.onboardingStatus?.join?.(','),
    'filters[onboardingStatus][--not-eq--]': filters.onboardingStatusNot?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/person/admin/manager', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getUserFromAdmin = (uid) => api.get(`/person/admin/manager/${uid}`);

export const getPropertiesFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    'filters[type][--in--]': filters.type?.join?.(','),
    'filters[rentExcludingCharges][--min--]': utils.euroToCents(filters.rentPriceMin?.[0]) || undefined,
    'filters[rentExcludingCharges][--max--]': utils.euroToCents(filters.rentPriceMax?.[0]) || undefined,
    'filters[ownerUid][--in--]': filters.ownerUid?.join?.(','),
    'filters[managerUid][--in--]': filters.managerUid?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/property/admin/properties', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getPropertyFromAdmin = (uid) => api.get(`/property/admin/properties/${uid}`);

export const updatePropertyFromAdmin = (uid, data) => api.put(`/property/admin/properties/${uid}`, data);

export const updatePropertyCharacteristicsFromAdmin = (uid, d) => api.put(`/property/admin/properties/${uid}/characteristics`, d);

export const getOwnersFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.id?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    'filters[onboardingStatus][--not-eq--]': filters.onboardingStatusNot?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/person/admin/property-owners', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getOwnerFromAdmin = (uid) => api.get(`/person/admin/property-owners/${uid}`);

export const getTenantsFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[id][--in--]': filters.id?.join?.(','),
    'filters[orderBy][--in--]': filters.orderBy?.join?.(','),
    'filters[onboardingStatus][--not-eq--]': filters.onboardingStatus?.join?.(','),
    q: filters.search?.[0],
  };

  return api.get('/person/admin/tenant', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getTenantFromAdmin = (uid) => api.get(`/person/admin/tenant/${uid}`);

export const updateTenantFromAdmin = (uid, data) => api.put(`/person/admin/tenant/${uid}`, data);

export const getPaymentsFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const allStatusFilters = filters?.status?.flatMap((s) => s.split(','));
  const statusFilters = allStatusFilters?.filter((s) => [
    'scheduled',
    'scheduled_manual',
    'notified',
    'pending',
    'canceled',
    'success',
    'failed',
  ].includes(s));
  const accountingsStatusFilters = allStatusFilters?.filter((s) => [
    'received',
    'sent',
    'ready_to_wire',
    'ready_to_check',
    'in_wiring',
    'failed',
    'not_doable',
  ].includes(s));
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--in--]': statusFilters?.length > 0
      ? statusFilters.join?.(',')
      : undefined,
    'filters[accountingsStatus][--in--]': accountingsStatusFilters?.length > 0
      ? accountingsStatusFilters.join?.(',')
      : undefined,
    ...getDateFilters('cashInDate', filters),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/admin/payment', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getCompaniesFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters?.uid?.join?.(','),
    'filters[onboardingStatus][--in--]': filters?.onboardingStatus?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/person/admin/profile/companies', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getAccountingFromAdmin = (uid) => api.get(`/payment/users/${uid}/account`);
