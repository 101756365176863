/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';

// Components
import {
  Picto,
  Tag,
  utils,
  Button,
  UnlockerLoader,
} from 'ui-library-unlocker';
import RegisterPaymentModal from '../../components/templates/RegisterPaymentModal/RegisterPaymentModal';
import DashboardBasics from '../../components/organisms/DashboardBasics/DashboardBasics';
import OnboardingTunnel from '../OnboardingTunnel/OnboardingTunnel';
import AdminKPI from './AdminKPI/AdminKPI';
import OnboardingProvider from '../../store/onboardingContext';
import Modal from '../../components/molecules/Modal/Modal';

// Hooks
import { useAppContext } from '../../store/context';
import useProfileCompletion from '../../hooks/useProfileCompletion';
import useRoles from '../../hooks/useRoles';
import useProfile from '../../hooks/useProfile';

// Utils
import { showModal, hideModal } from '../../utils/modal';

// Services
import { validateOnboardingBasicsStatus } from '../../services/identity';
import { completeOnboardingFunnel } from '../../services/onboarding';

// Constants
import { ENROLMENT_STATUS } from '../../utils/constants';

import styles from './Dashboard.module.scss';

const IGNORE_ONBOARDING_CONFIRM_MODAL_ID = 'ignore-onboarding-confirm-modal';

function Dashboard() {
  const { t } = useTranslation();
  const { profile } = useProfile();
  const {
    onboardingStatus,
  } = useProfileCompletion();
  const {
    isUserAdmin,
    isUserLessor,
    isUserTenant,
  } = useRoles();
  const {
    context: { me, hasCompletedOnboardingFunnel, skipOnboarding },
    dispatch,
  } = useAppContext();

  useEffect(() => {
    document.title = t('dashboard.browserTitle');
  }, [t]);

  const validateOnboardingMutation = useMutation({
    mutationFn: validateOnboardingBasicsStatus,
  });

  const endOnboardingMutation = useMutation({
    mutationFn: completeOnboardingFunnel,
    onSuccess: () => {
      localStorage.setItem('hasCompletedOnboardingFunnel', true);
      return dispatch({
        type: 'SET_HAS_COMPLETED_ONBOARDING_FUNNEL',
        payload: true,
      });
    },
    onError: () => utils.toast.error(t('global.form.errors.global')),
  });

  const formStatus = useMemo(() => {
    if (!onboardingStatus) return null;
    switch (onboardingStatus) {
      case ENROLMENT_STATUS.PENDING:
      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
        return {
          variant: 'tertiary',
        };
      case ENROLMENT_STATUS.PROFILE_DONE:
        return {
          variant: 'primary',
        };
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
        return {
          variant: 'error',
        };
      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
      case ENROLMENT_STATUS.COMPLETED:
        return {
          variant: 'success',
        };
      default:
        return {
          variant: 'tertiary',
        };
    }
  }, [onboardingStatus]);

  const hasCompletedOnboardingBasics = useMemo(() => {
    if (validateOnboardingMutation?.isSuccess) return true;
    return me?.hasCompletedOnboardingBasics;
  }, [me, validateOnboardingMutation]);

  const renderNoBasics = useMemo(() => (
    <>
      <p className={utils.cn(['m-t-40 p-1-300', styles.descriptions])}>
        {t(`dashboard.descriptions.${onboardingStatus}`)}
      </p>
      {onboardingStatus === ENROLMENT_STATUS.PENDING && (
      <Link to="/profile#personal-info">
        <Button className="m-t-20" variant="primary" size="large">
          {t('completeProfile.cta')}
        </Button>
      </Link>
      )}
      <img className={styles.mask} src="/images/appartment-1.png" alt="cover" />
    </>
  ), [onboardingStatus, isUserAdmin]);

  const handleValidateOnboardingUserStatus = useCallback(() => {
    validateOnboardingMutation.mutate();
  }, [validateOnboardingMutation]);

  const resumeOnboarding = useCallback(() => {
    dispatch({
      type: 'SET_SKIP_ONBOARDING',
      payload: false,
    });
  }, [dispatch]);

  if (typeof hasCompletedOnboardingFunnel === 'undefined') return <UnlockerLoader size={300} />;

  if (!hasCompletedOnboardingFunnel && !isUserAdmin && !skipOnboarding) {
    return (
      <OnboardingProvider>
        <OnboardingTunnel />
      </OnboardingProvider>
    );
  }

  if (!onboardingStatus) return <UnlockerLoader size={300} />;

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.titleBlock}>
          <h1>{t(isUserAdmin ? 'dashboard.adminTitle' : 'dashboard.title')}</h1>
          {!isUserAdmin && formStatus && (
            <Tag
              label={t(`profile.profileState.${onboardingStatus}`)}
              size="medium"
              variant={formStatus?.variant}
            />
          )}
        </div>
        {isUserLessor && !isUserAdmin && (
          <Button
            variant="primary"
            size="medium"
            onClick={() => showModal('dashboard-register-payment-modal')}
            className={styles.registerPaymentBtn}
          >
            {t('dashboard.registerPayment.cta')}
          </Button>
        )}
      </div>

      <div className={styles.content}>

        <h2 className={utils.cn(['m-t-10 m-b-4', styles.welcomeTitle])}>
          {profile?.firstName ? t('dashboard.titles.profile_done', { firstName: profile?.firstName }) : t('dashboard.titles.pending')}
        </h2>

        <Picto width={166} icon="trace-5208" color="var(--color-tertiary)" />

        {(!hasCompletedOnboardingFunnel && !isUserAdmin && !isUserTenant) && (
          <div className="m-t-20 m-b-30">
            <p>
              <Trans i18nKey="dashboard.onboardingSkipped" />
            </p>
            <div className={utils.cn([styles.onboardingFunnelCTAs, 'm-t-15'])}>
              <Button
                variant="primary"
                size="large"
                onClick={resumeOnboarding}
              >
                {t('dashboard.resumeOnboarding')}
              </Button>
              <button
                type="button"
                className={styles.ignoreOnboardingBtn}
                onClick={() => showModal(IGNORE_ONBOARDING_CONFIRM_MODAL_ID)}
              >
                {t('dashboard.ignoreOnboarding')}
              </button>
            </div>

            {/* Send validation request modal */}
            <Modal
              id={IGNORE_ONBOARDING_CONFIRM_MODAL_ID}
              title={t('dashboard.ignoreOnboardingConfirm')}
              size="large"
              onClose={() => hideModal(IGNORE_ONBOARDING_CONFIRM_MODAL_ID)}
            >
              <Button
                className="m-t-40 center-block"
                size="large"
                loading={endOnboardingMutation?.isLoading}
                onClick={endOnboardingMutation.mutate}
              >
                {t('global.validate')}
              </Button>
            </Modal>
          </div>
        )}

        {(hasCompletedOnboardingBasics === false && isUserLessor) && (
          <>
            <DashboardBasics
              handleValidateOnboardingUserStatus={handleValidateOnboardingUserStatus}
              validating={validateOnboardingMutation.isLoading}
              className={styles.dashboardBasics}
            />
            {!isUserAdmin && (
              <Picto width={166} icon="gribouillon-6" fill="var(--color-secondary)" className={styles.deco} />
            )}
          </>
        )}

        {hasCompletedOnboardingBasics === true && !isUserAdmin && (
          renderNoBasics
        )}

      </div>

      {isUserLessor && !isUserAdmin && (
        <RegisterPaymentModal id="dashboard-register-payment-modal" />
      )}

      {isUserAdmin && (
        <AdminKPI />
      )}
    </div>
  );
}

Dashboard.propTypes = {
};

export default Dashboard;
