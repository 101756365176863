import React, { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Picto,
  Tag,
  utils,
  Button,
} from 'ui-library-unlocker';

// Components
import LeaseTenants from './LeaseTenants/LeaseTenants';
import LeaseDocuments from './LeaseDocuments/LeaseDocuments';
import LeaseRevisionList from './LeaseRevisionList/LeaseRevisionList';
import LeaseGeneralData from './LeaseGeneralData/LeaseGeneralData';
import LeasePayment from './LeasePayment/LeasePayment';
import LeaseHousingBenefits from './LeaseHousingBenefits/LeaseHousingBenefits';
import LeaseRevision from '../../components/molecules/LeaseRevision/LeaseRevision';
import LeaseForm from '../../components/organisms/forms/LeaseForm/LeaseForm';

// Services
import { getLease, getLeaseBankInfo } from '../../services/lease';
import { getTenants } from '../../services/tenant';
import { getUsersFromAdmin } from '../../services/admin';
import { getAvailableBankingIdentities, getTenantAvailableBankingIdentities } from '../../services/bankingIdentity';

// Hooks
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';

// Utils
import { showModal } from '../../utils/modal';
import { getLeaseStatusVariant } from '../../utils/variants';

// Constants
import { LEASE_STATUS } from '../../utils/constants';

import styles from './LeaseEdit.module.scss';

const LEASE_REVISION_MODAL_ID = 'lease-revision-modal';

function LeaseEdit() {
  const { t } = useTranslation();
  const location = useLocation();
  const { isUserLessor, isUserAdmin, isUserTenant } = useRoles();
  const { context: { user } } = useAppContext();
  const {
    leasePayment,
  } = useFlags();

  const match = useMatch('/lease/:id');

  useEffect(() => {
    switch (location.hash) {
      case '#documents':
        document.title = t('lease.tabs.documents.browserTitle');
        break;
      case '#tenants':
        document.title = t('lease.tabs.tenants.browserTitle');
        break;
      case '#payment':
        document.title = t('lease.tabs.payment.browserTitle');
        break;
      case '#housing-benefits':
        document.title = t('lease.tabs.housingBenefits.browserTitle');
        break;
      case '#revisions':
        document.title = t('lease.tabs.revisions.browserTitle');
        break;
      default:
        document.title = t('lease.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const leaseQuery = useQuery({
    queryKey: ['lease-id', match.params?.id],
    queryFn: () => getLease(match?.params?.id),
    cacheTime: 0,
  });

  const {
    name,
    status,
    revisions,
    hasBeenRevoked,
    tenants: tenantUids,
  } = leaseQuery?.data?.data || {};

  const bankInfoQuery = useQuery({
    queryKey: ['lease-bank-info-id', match.params?.id],
    queryFn: () => getLeaseBankInfo(match?.params?.id),
    cacheTime: 0,
  });

  const tenantsQuery = useQuery({
    queryKey: ['lease-tenants-list', tenantUids, isUserAdmin],
    queryFn: () => (isUserAdmin ? getUsersFromAdmin : getTenants)({
      filters: {
        uid: tenantUids,
      },
    }),
    keepPreviousData: true,
    enabled: !!tenantUids?.length > 0 && !isUserTenant,
    cacheTime: 0,
  });

  const tenantsData = useMemo(() => tenantsQuery?.data?.data?.collection || [], [tenantsQuery]);

  const delegatedTenants = useMemo(() => (
    tenantsData.filter((tenant) => tenant.isDelegated && tenant.delegatedTo === user?.username)
  ), [tenantsData, user]);

  const delegatedTenantUID = useMemo(() => delegatedTenants[0]?.uid, [delegatedTenants]);

  const bankingIdentitiesQuery = useQuery({
    queryKey: ['banking-identities-available-list', match?.params?.id, delegatedTenantUID],
    queryFn: () => {
      if (delegatedTenantUID) return getTenantAvailableBankingIdentities(delegatedTenantUID, match?.params?.id);
      return getAvailableBankingIdentities(match?.params?.id);
    },
    keepPreviousData: true,
    enabled: !!(isUserTenant || delegatedTenantUID),
    cacheTime: 0,
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          if (err?.response?.data?.message === 'PAYMENT_LEASE_PAYMENT_METHOD_PAYER_IS_NOT_A_TENANT_EXCEPTION') {
            utils.toast.error(t(`bankingIdentity.crud.formErrors.${err.response.data.message}`));
          } else {
            utils.toast.error(t('global.form.errors.global'));
          }
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const bankingIdentitiesData = useMemo(() => {
    const data = bankingIdentitiesQuery?.data?.data;
    if (!data) return [];
    return data.flatMap((tenant) => tenant.identities);
  }, [bankingIdentitiesQuery]);

  const tabs = useMemo(() => [
    {
      label: t('lease.tabs.generalInfo.tabTitle'),
      title: t('lease.tabs.generalInfo.title'),
      hash: '',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: status === LEASE_STATUS.WAITING_DOCUMENT && isUserLessor ? (
        <LeaseForm leaseRealStatus={LEASE_STATUS.CREATED} leaseQuery={leaseQuery} />
      ) : (
        <LeaseGeneralData
          lease={leaseQuery?.data?.data}
          bankInfo={bankInfoQuery?.data?.data}
          error={leaseQuery?.isError}
        />
      ),
      show: true,
    },
    {
      label: t('lease.tabs.tenants.tabTitle'),
      title: t('lease.tabs.tenants.title'),
      hash: 'tenants',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <LeaseTenants tenantList={tenantsData} isLoading={tenantsQuery?.isFetching} />
      ),
      show: !isUserTenant,
    },
    {
      label: t('lease.tabs.payment.tabTitle'),
      title: t('lease.tabs.payment.title'),
      hash: 'payment',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <LeasePayment
          bankingIdentitiesData={bankingIdentitiesData}
          isLoading={bankingIdentitiesData?.isFetching || leaseQuery?.isFetching}
          error={bankingIdentitiesData?.isError}
          hasBeenRevoked={hasBeenRevoked}
          lease={leaseQuery?.data?.data}
          bankInfo={bankInfoQuery?.data?.data}
          bankInfoRefetch={bankInfoQuery?.refetch}
          leaseUID={match?.params?.id}
          delegatedTenants={delegatedTenants}
          delegatedTenantUID={delegatedTenantUID}
        />
      ),
      // show: true,
      show: leasePayment,
    },
    {
      label: t('lease.tabs.housingBenefits.tabTitle'),
      title: t('lease.tabs.housingBenefits.title'),
      hash: 'housingBenefits',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <LeaseHousingBenefits
          isLoading={bankInfoQuery?.isLoading || leaseQuery?.isFetching}
          error={bankInfoQuery?.isError}
          hasBeenRevoked={hasBeenRevoked}
          lease={leaseQuery?.data?.data}
          bankInfo={bankInfoQuery?.data?.data}
          bankInfoRefetch={bankInfoQuery?.refetch}
        />
      ),
      // show: true,
      show: leasePayment,
    },
    {
      label: t('lease.tabs.documents.tabTitle'),
      title: t('lease.tabs.documents.title'),
      hash: 'documents',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <LeaseDocuments />
      ),
      show: true,
    },
    {
      label: t('lease.tabs.revisions.tabTitle'),
      title: t('lease.tabs.revisions.title'),
      hash: 'revisions',
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <LeaseRevisionList
          revisionList={revisions}
          isFetching={leaseQuery?.isFetching}
          lease={leaseQuery?.data?.data}
          isFetched={leaseQuery?.isFetched}
          hasBeenRevoked={hasBeenRevoked}
          LEASE_REVISION_MODAL_ID={LEASE_REVISION_MODAL_ID}
        />
      ),
      show: true,
    },
  ], [
    t,
    location,
    tenantsData,
    leaseQuery,
    revisions,
    isUserLessor,
    isUserTenant,
    bankInfoQuery,
    hasBeenRevoked,
    tenantsQuery,
    bankingIdentitiesData,
    bankingIdentitiesQuery,
    delegatedTenants,
    delegatedTenantUID,
    match,
  ]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => cur.hash === hash && cur.show);
    return tab || tabs[0];
  }, [tabs, location]);

  const handleRevision = useCallback(() => {
    showModal(LEASE_REVISION_MODAL_ID);
  }, []);

  const showRevision = useMemo(() => (
    !hasBeenRevoked && isUserLessor && status && status !== LEASE_STATUS.WAITING_DOCUMENT
  ), [hasBeenRevoked, isUserLessor, status]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <div className={styles.editDetails}>
            <h1>
              {t(`lease.crud.${isUserTenant ? 'readOnlyTitle' : 'editTitle'}`)}
            </h1>
          </div>
          {leaseQuery?.data && (
            <div className={utils.cn(['m-t-15', styles.editDetails])}>
              <h2 className="t-h2-500">{name}</h2>
              <Tag
                label={t(`lease.table.status.${status}`)}
                size="medium"
                variant={getLeaseStatusVariant(status)}
              />
            </div>
          )}
          {showRevision && (
            <Button
              label={t('lease.revision')}
              variant="primary"
              size="medium"
              icon="edit"
              className="m-t-20"
              onClick={handleRevision}
            />
          )}
        </div>
        <ul className={styles.tabList}>
          {status && status !== LEASE_STATUS.WAITING_DOCUMENT && tabs.map((tab) => {
            if (!tab.show) return null;
            const isActive = tab.hash === currentTab.hash;

            return (
              <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                <Link to={`#${tab.hash}`}>{tab.label}</Link>
                {isActive && tab.activePicto}
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
      {showRevision && (
        <LeaseRevision
          id={LEASE_REVISION_MODAL_ID}
          lease={leaseQuery?.data?.data}
          isLoading={leaseQuery?.isFetching}
          refetchLease={leaseQuery?.refetch}
        />
      )}
    </div>
  );
}

export default LeaseEdit;
